<template>
  <div class="body-wrapper">
    <Headernav class="hidden md:block" />
    <div class="content-wrapper">
      <div class="contact-bg section padding-top">
        <div class="container text-center">
          <div class="section-title text-white mb-3">MESSAGE SENDING SUCCESSFUL!</div>
          <div class="text-common text-white mb-12">Thank you for your kind interest in Federal Concept Inc. We will get back to you as soon as possible.</div>
          <router-link class="m-auto" to="/"><div class="yellow-btn">Back To Home</div></router-link>
        </div>
      </div>
      <div class="w-full">
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15446.125229722242!2d121.0849779!3d14.5687743!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3b1c474e4d6ca1cc!2sFederal%20Concepts%20Inc.!5e0!3m2!1sen!2sph!4v1581324711988!5m2!1sen!2sph"
        width="100%"
        height="450"
        frameborder="0"
        style="border:0;"
        allowfullscreen="">
        </iframe>
      </div>
    </div>
    <div class="footer-wrapper">
      <div class="container text-center border-t-2 border-blue-900 pt-4 pb-6">
        <div class="w-full text-common">Maintained and Developed by AMPH Advertising Agency Inc.</div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias for src
import Headernav from '@/components/Headernav.vue'
export default {
  components: {
    Headernav
  },
  mounted () {
    // Event snippet for Conversion Code conversion page
    // eslint-disable-next-line
    gtag('event', 'conversion', {'send_to': 'AW-742425677/YkZBCK2NmZ4BEM2IguIC'});
  }
}
</script>
<style>
  .padding-top {
    padding-top: 4rem;
  }
  @media (min-width: 767px) {
		.padding-top {
      padding-top: 18rem;
    }
  }
</style>
